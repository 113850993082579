@import 'bootstrap-variables';
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import '~@angular/material/prebuilt-themes/azure-blue.css';
@import 'https://fonts.googleapis.com/icon?family=Material+Icons';
/* Core Data Grid CSS */
@import 'ag-grid-community/styles/ag-grid.css';
/* Quartz Theme Specific CSS */
@import 'ag-grid-community/styles/ag-theme-quartz.css';
@import '../theming/button.scss';
//@import 'ag-grid-community/styles/agGridMaterialFont.css';
@import '@danielmoncada/angular-datetime-picker/assets/style/picker.min.css';

/* ==============================================================
Bootstrap tweaks
===============================================================*/

body,
h1,
h2,
h3,
h4 {
  font-weight: 300;
}

/* Increase contrast of links to get 100% on Lighthouse Accessibility Audit. Override this color if you want to change the link color, or use a Bootswatch theme */
a {
  color: #533f03;
  font-weight: bold;
}

a:hover {
  color: #533f03;
}

/* override hover color for dropdown-item forced by bootstrap to all a:not([href]):not([tabindex]) elements in _reboot.scss */
a:not([href]):not([tabindex]):hover.dropdown-item {
  color: $dropdown-link-hover-color;
}

/* override .dropdown-item.active background-color on hover */
.dropdown-item.active:hover {
  background-color: mix($dropdown-link-hover-bg, $dropdown-link-active-bg, 50%);
}

a:hover {
  /* make sure browsers use the pointer cursor for anchors, even with no href */
  cursor: pointer;
}

.dropdown-item:hover {
  color: $dropdown-link-hover-color;
}

/* ==========================================================================
Browser Upgrade Prompt
========================================================================== */
.browserupgrade {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0;
}

/* ==========================================================================
Generic styles
========================================================================== */

/* Error highlight on input fields */
// .ng-valid[required],
// .ng-valid.required {
//   border-left: 5px solid green;
// }

// .ng-invalid:not(form) {
//   border-left: 5px solid red;
// }

/* other generic styles */

.jh-card {
  padding: 1.5%;
  margin-top: 20px;
  border: none;
}

.error {
  color: white;
  background-color: red;
}

.pad {
  padding: 10px;
}

.w-40 {
  width: 40% !important;
}

.w-60 {
  width: 60% !important;
}

.break {
  white-space: normal;
  word-break: break-all;
}

.form-control {
  background-color: #fff;
}

.readonly {
  background-color: #eee;
  opacity: 1;
}

.footer {
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}

.hand,
[jhisortby] {
  cursor: pointer;
}

/* ==========================================================================
Custom alerts for notification
========================================================================== */
.alerts {
  .alert {
    text-overflow: ellipsis;

    pre {
      background: none;
      border: none;
      font: inherit;
      color: inherit;
      padding: 0;
      margin: 0;
    }

    .popover pre {
      font-size: 10px;
    }
  }

  .jhi-toast {
    position: fixed;
    width: 100%;

    &.left {
      left: 5px;
    }

    &.right {
      right: 5px;
    }

    &.top {
      top: 55px;
    }

    &.bottom {
      bottom: 55px;
    }
  }
}

@media screen and (min-width: 480px) {
  .alerts .jhi-toast {
    width: 50%;
  }
}

/* ==========================================================================
entity list page css
========================================================================== */

.table-entities thead th .d-flex > * {
  margin: auto 0;
}

/* ==========================================================================
entity detail page css
========================================================================== */
.row-md.jh-entity-details {
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: 10px;
  line-height: 1.5;
}

@media screen and (min-width: 768px) {
  .row-md.jh-entity-details > {
    dt {
      float: left;
      overflow: hidden;
      clear: left;
      text-align: right;
      text-overflow: ellipsis;
      white-space: nowrap;
      padding: 0.5em 0;
    }

    dd {
      border-bottom: 1px solid #eee;
      padding: 0.5em 0;
      margin-left: 0;
    }
  }
}

/* ==========================================================================
ui bootstrap tweaks
========================================================================== */
.nav,
.pagination,
.carousel,
.panel-title a {
  cursor: pointer;
}

.thread-dump-modal-lock {
  max-width: 450px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.dropdown-menu {
  padding-left: 0px;
}

/* ==========================================================================
angular-cli removes postcss-rtl processed inline css, processed rules must be added here instead
========================================================================== */
/* page-ribbon.component.scss */
.ribbon {
  left: -3.5em;
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

/* navbar.component.scss */
.navbar {
  ul.navbar-nav {
    .nav-item {
      margin-left: 0.5em;
    }
  }
}

/* jhipster-needle-scss-add-main JHipster will add new css style */

::ng-deep .mat-mdc-dialog-container {
  background-color: white !important;
}

::ng-deep .mat-dialog-container {
  background-color: white !important;
}

.mat-mdc-dialog-container {
  background-color: white;
}

mat-form-field {
  --mat-form-field-appearance: outline;
  --mat-form-field-container-height: 34px;
  --mat-form-field-container-vertical-padding: 8px;
  --mdc-outlined-text-field-container-shape: 2px;
  --mdc-outlined-text-field-label-text-tracking: normal;

  .mdc-text-field--outlined,
  .mdc-text-field {
    .mat-mdc-form-field-icon-prefix,
    .mat-mdc-form-field-icon-suffix {
      & > .mat-icon {
        padding: 8px;
      }
    }

    .mat-mdc-form-field-icon-prefix > .mat-icon {
      padding-right: 4px;
    }

    .mdc-notched-outline {
      .mdc-notched-outline_notch {
        border-right: non !important;
      }
    }
  }
}

.ag-theme-quartz {
  width: auto !important;
  height: auto;
  --ag-grid-size: 3px;
  --ag-font-size: 12px;
  --ag-font-family: Roboto, 'Helvetica Neue', Arial, Helvetica, sans-serif;
  .ag-popup-child {
    width: max-content !important;
    max-width: max-content !important;
  }
  // --ag-icon-font-family: 'agGridMaterial';

  // --ag-wrapper-boder-radius:1;
  // //--ag-border-color:#262626;
  // --ag-background-color:#fff;
  //--ag-foreground-color: rgba(0,0,0,0.87)

  .ag-root-wrapper-body.ag-layout-normal {
    border: 1 px solid #e0e0e0;
  }

  .ag-row-hover,
  .ag-row-focus {
    background-color: #f2f2f2;
  }

  .ag-header-cell {
    font-size: 12px;
    line-height: 1rem;
    font-weight: 700;
  }

  // .ag-paging-panel {
  //   --ag-grid-size: 14px;
  //   --ag-font-size: 14px;
  //   --ag-header-height: 35px;
  // }

  &.hide-paging-page-size {
    height: 325.12px;

    .ag-paging-panel .ag-paging-page-size {
      display: none !important;
    }
  }
}

.pageTitle {
  font-size: 22px !important;
  font-weight: 500;
  float: left;
  margin: 8px 5px 8px 0px;
}

.header-title {
  font-size: 1.4rem;
  line-height: 2rem;
  padding-left: 3px;
  padding-right: 1.25rem;
  font-weight: 700;
}

.example-spacer {
  flex: 1 0 auto;
}

.tcn-spinner {
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: absolute;
  inset: 0;
  z-index: 10;
  align-items: center;
}

.tcn-supplier-selected {
  --ag-selected-row-background-color: #f5f5f5 !important;
  .ag-row-selected {
    --ag-row-border-color: #00000033 !important;
    --ag-row-border-width: 2px !important;
    border-top: var(--ag-row-border-style) var(--ag-row-border-color) var(--ag-row-border-width);
    border-right: 0;
    position: fixed !important;
    width: 43.3% !important;
  }
  cursor: pointer;
}

.rfq-status {
  display: flex;
  border-radius: 6px;
  font-weight: 600;
  color: #000;
}
.open-status {
  background-color: #e0f7fa;
}

.save-draft-status {
  background-color: #fcf805;
}

.live-status {
  background-color: lightgreen;
}

.closed-status {
  background-color: #f0f0f0;
}

.rejacted-status {
  background-color: #e3b795;
}
.publish-status {
  background-color: #63cad1;
}

.rescheduled-status {
  background-color: hsl(193, 71%, 78%);
}

.status-invitation {
  background-color: #fad296;
}

.scheduled-status {
  background-color: #f8cfff;
}

.status-rendere {
  border-radius: 6px;
  width: 80%;
  margin-left: 3px;
}
.status-text {
  margin-left: 5%;
}

.custom-error {
  color: #ba1a1a;
  font-size: 0.75rem;
}

.not-allowed {
  cursor: not-allowed !important;
}

// button.mdc-button[disabled],
// button.mat-mdc-button[disabled] {
//   pointer-events: none;
//   cursor: not-allowed;
//   pointer-events: none;
// }
